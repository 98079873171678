{
  "name": "iD",
  "version": "2.27.3",
  "description": "A friendly editor for OpenStreetMap",
  "main": "dist/iD.min.js",
  "repository": "github:openstreetmap/iD",
  "homepage": "https://github.com/openstreetmap/iD",
  "bugs": "https://github.com/openstreetmap/iD/issues",
  "keywords": [
    "editor",
    "openstreetmap"
  ],
  "license": "ISC",
  "scripts": {
    "all": "run-s clean build dist",
    "build": "run-s build:css build:data build:js",
    "build:css": "node scripts/build_css.js",
    "build:data": "shx mkdir -p dist/data && node scripts/build_data.js",
    "build:stats": "node config/esbuild.config.mjs --stats && esbuild-visualizer --metadata dist/esbuild.json --exclude *.png --filename docs/statistics.html && shx rm dist/esbuild.json",
    "build:js": "node config/esbuild.config.mjs",
    "build:js:watch": "node config/esbuild.config.mjs --watch",
    "clean": "shx rm -f dist/esbuild.json dist/*.js dist/*.map dist/*.css dist/img/*.svg",
    "dist": "run-p dist:**",
    "dist:mapillary": "shx mkdir -p dist/mapillary-js && shx cp -R node_modules/mapillary-js/dist/* dist/mapillary-js/",
    "dist:pannellum": "shx mkdir -p dist/pannellum && shx cp -R node_modules/pannellum/build/* dist/pannellum/",
    "dist:min": "node config/esbuild.config.min.mjs",
    "dist:svg:iD": "svg-sprite --symbol --symbol-dest . --shape-id-generator \"iD-%s\" --symbol-sprite dist/img/iD-sprite.svg \"svg/iD-sprite/**/*.svg\"",
    "dist:svg:community": "svg-sprite --symbol --symbol-dest . --shape-id-generator \"community-%s\" --symbol-sprite dist/img/community-sprite.svg node_modules/osm-community-index/dist/img/*.svg",
    "dist:svg:fa": "svg-sprite --symbol --symbol-dest . --symbol-sprite dist/img/fa-sprite.svg svg/fontawesome/*.svg",
    "dist:svg:maki": "svg-sprite --symbol --symbol-dest . --shape-id-generator \"maki-%s\" --symbol-sprite dist/img/maki-sprite.svg node_modules/@mapbox/maki/icons/*.svg",
    "dist:svg:mapillary:signs": "svg-sprite --symbol --symbol-dest . --symbol-sprite dist/img/mapillary-sprite.svg node_modules/mapillary_sprite_source/package_signs/*.svg",
    "dist:svg:mapillary:objects": "svg-sprite --symbol --symbol-dest . --symbol-sprite dist/img/mapillary-object-sprite.svg node_modules/mapillary_sprite_source/package_objects/*.svg",
    "dist:svg:roentgen": "svg-sprite --shape-id-generator \"roentgen-%s\" --shape-dim-width 16 --shape-dim-height 16 --symbol --symbol-dest . --symbol-sprite dist/img/roentgen-sprite.svg svg/roentgen/*.svg",
    "dist:svg:temaki": "svg-sprite --symbol --symbol-dest . --shape-id-generator \"temaki-%s\" --symbol-sprite dist/img/temaki-sprite.svg node_modules/@rapideditor/temaki/icons/*.svg",
    "imagery": "node scripts/update_imagery.js",
    "lint": "eslint config scripts test/spec modules --ext js,mjs",
    "lint:fix": "eslint scripts test/spec modules --fix",
    "start": "run-s start:watch",
    "start:single-build": "run-p build:js start:server",
    "start:watch": "run-p build:js:watch start:server",
    "start:server": "node scripts/server.js",
    "test": "npm-run-all -s lint build test:spec",
    "test:spec": "karma start config/karma.conf.js",
    "translations": "node scripts/update_locales.js"
  },
  "dependencies": {
    "@mapbox/geojson-area": "^0.2.2",
    "@mapbox/sexagesimal": "1.2.0",
    "@mapbox/vector-tile": "^1.3.1",
    "@rapideditor/country-coder": "~5.2.0",
    "@rapideditor/location-conflation": "~1.2.1",
    "@tmcw/togeojson": "^5.8.1",
    "@turf/bbox": "^6.0.0",
    "@turf/bbox-clip": "^6.0.0",
    "abortcontroller-polyfill": "^1.7.5",
    "aes-js": "^3.1.2",
    "alif-toolkit": "^1.2.9",
    "core-js-bundle": "^3.33.2",
    "diacritics": "1.3.0",
    "exifr": "^7.1.3",
    "fast-deep-equal": "~3.1.1",
    "fast-json-stable-stringify": "2.1.0",
    "lodash-es": "~4.17.15",
    "marked": "~7.0.3",
    "node-diff3": "~3.1.0",
    "osm-auth": "~2.2.0",
    "pannellum": "2.5.6",
    "pbf": "^3.2.1",
    "polygon-clipping": "~0.15.1",
    "rbush": "3.0.1",
    "whatwg-fetch": "^3.6.17",
    "which-polygon": "2.2.1"
  },
  "devDependencies": {
    "@fortawesome/fontawesome-svg-core": "~6.4.2",
    "@fortawesome/free-brands-svg-icons": "~6.4.2",
    "@fortawesome/free-regular-svg-icons": "~6.4.2",
    "@fortawesome/free-solid-svg-icons": "~6.4.2",
    "@mapbox/maki": "^8.0.1",
    "@openstreetmap/id-tagging-schema": "^6.4.1",
    "@rapideditor/temaki": "^5.6.0",
    "@transifex/api": "^5.4.0",
    "autoprefixer": "^10.4.15",
    "browserslist-to-esbuild": "^1.2.0",
    "chai": "^4.3.10",
    "chalk": "^4.1.2",
    "cldr-core": "^43.0.0",
    "cldr-localenames-full": "^43.1.0",
    "concat-files": "^0.1.1",
    "d3": "~7.8.5",
    "dotenv": "^16.3.1",
    "editor-layer-index": "github:osmlab/editor-layer-index#gh-pages",
    "esbuild": "^0.19.2",
    "esbuild-visualizer": "^0.4.1",
    "eslint": "^8.47.0",
    "fetch-mock": "^9.11.0",
    "gaze": "^1.1.3",
    "glob": "^10.3.3",
    "happen": "^0.3.2",
    "js-yaml": "^4.0.0",
    "json-stringify-pretty-compact": "^3.0.0",
    "karma": "^6.4.2",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "2.1.1",
    "karma-mocha": "^2.0.1",
    "karma-remap-istanbul": "^0.6.0",
    "mapillary_sprite_source": "^1.8.0",
    "mapillary-js": "4.1.1",
    "minimist": "^1.2.8",
    "mocha": "^10.2.0",
    "name-suggestion-index": "~6.0",
    "node-fetch": "^2.7.0",
    "npm-run-all": "^4.0.0",
    "osm-community-index": "~5.6.0",
    "postcss": "^8.4.31",
    "postcss-selector-prepend": "^0.5.0",
    "shelljs": "^0.8.0",
    "shx": "^0.3.0",
    "sinon": "^11.1.2",
    "sinon-chai": "^3.7.0",
    "smash": "0.0",
    "static-server": "^2.2.1",
    "svg-sprite": "2.0.2",
    "vparse": "~1.1.0"
  },
  "engines": {
    "node": ">=16.14"
  },
  "browserslist": [
    "> 0.3%, last 6 major versions, Firefox ESR, maintained node versions"
  ]
}
