/** --------------------------------------------------------------------------
  monkey patching oauth out of the way (CF)
*/

function doRawXhr(method, url, data, headers, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function() {
    if (4 === xhr.readyState && 0 !== xhr.status) {
      if (/^20\d$/.test(xhr.status)) {
        callback(null, xhr);
      } else {
        callback(xhr, null);
      }
    }
  };
  xhr.onerror = function(e) {
    callback(e, null);
  };
  xhr.open(method, url, true);
  for (var h in headers)
    xhr.setRequestHeader(h, headers[h]);
  xhr.send(data);
  return xhr;
};

function noop() {};

// Utility function to add Authorization header for OAuth 2.0
function addOAuth2Header(headers, token) {
  headers["Authorization"] = "Bearer " + token;
}

// disable oauth 2
export const osmAuth = function (opts) {
  return {
    // Assuming you have a way to get and store the access token
    accessToken: null,

    // Method to set access token
    setAccessToken: function(token) {
      this.accessToken = token;
    },

    authenticated: function() {
      return true;
    },

    // You'll need to implement logout according to your OAuth 2.0 setup
    logout: function() {
      // Implementation for logging out (e.g., clearing tokens)
      return this;
    },

    authenticate: function(cb) {
      // Implementation for authentication (e.g., opening a popup)
      // Call cb() when the authentication is complete
      cb();
    },

    bootstrapToken: function(token, cb) {
      // Implementation for bootstrapping token from URL (e.g., parsing hash)
      // Call cb() when the token has been bootstrapped
      cb(null, this);
    },

    // Update xhr method to include OAuth 2.0 Authorization header
    xhr: function(options, callback) {
      return run();

      function run() {
        var url = (options.prefix !== false) ? opts.url + options.path : options.path;
        var headers = (options.options && options.options.header) || {
          'Content-Type': 'application/x-www-form-urlencoded'
        };
        if (!!options.content) {
          headers['Content-Type'] = 'text/xml';
        }
        addOAuth2Header(headers, 'access_token');
        return doRawXhr(options.method, url, options.content, headers, done);
      }

      function done(err, xhr) {
        if (err) return callback(err);
        else if (xhr.responseXML) return callback(err, xhr.responseXML);
        else return callback(err, xhr.response);
      }
    },

    preauth: noop,
    options: noop
  }
}
